export const None = Symbol("none");
export function filterMap<T1, T2>(
  arr: T1[],
  fn: (arg: T1, index: number) => typeof None | T2
): T2[] {
  const ret: T2[] = [];
  for (let i = 0; i < arr.length; ++i) {
    const res = fn(arr[i], i);
    if (res !== None) {
      ret.push(res);
    }
  }
  return ret;
}
filterMap.None = None;

export function filterFlatMap<T1, T2>(
  arr: T1[],
  fn: (arg: T1, index: number) => typeof None | T2[]
): T2[] {
  const ret: T2[] = [];
  for (let i = 0; i < arr.length; ++i) {
    const res = fn(arr[i], i);
    if (res !== None) {
      ret.push(...res);
    }
  }
  return ret;
}
filterFlatMap.None = None;
